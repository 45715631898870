<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import { required, helpers } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import { reqMethods } from '../../../state/helpers';
import { LMap, LMarker, LTileLayer, LIcon } from "@vue-leaflet/vue-leaflet";
import { latLng } from "leaflet";
import AudioPlayer from 'vue3-audio-player'
import 'vue3-audio-player/dist/style.css'
import axios from 'axios';
import Swal from "sweetalert2";


/**
 * Email-read component
 */
export default {
  setup() {
    return {
      zoom: 12,
      maxZoom: 25,
      center: latLng(6.2012158618, 1.15410990516),
      url: 'http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      v$: useVuelidate()
    };
  },
  components: {
    Layout,
    PageHeader,
    LMap,
    LTileLayer,
    LMarker,
    LIcon,
    AudioPlayer
  },
  data() {
    return {
      title: "Détails de l'SOS",
      items: [
        {
          text: "SOS",
          href: "/sosListe"
        },
        {
          text: "Détails",
          active: true
        }
      ],
      coversrc: null,
      pertinence: null,
      alert: {},
      updates: {},
      alertId: null,
      position: {},
      userPosition: [],
      Markers: [],
      reqError: null,
      submitted: false,
      form: {
        message: "",
      },
      username: "Steven Franklin",
    };
  },
  created() {
  },
  validations: {
    form: {
      message: {
        required: helpers.withMessage("Message is required", required),
      },
    },
  },
  methods: {
    ...reqMethods,
    getAlertDetails() {
      const that = this;
      const id = { id: parseInt(this.$route.params.id) };
      this.postRequest({
        route: '/alert/alert-spontaneous/findbyid/',
        data: id,
      })
        .then(
          function (response) {
            that.getAlertUpdates;
            that.alert = response.data;
            that.pertinence = that.alert.alerts[0].pertinence;
          },
          function (error) {
            that.reqError = error;
          }
        )
    },
    getAlertUpdates(){
      const that = this;
      const id = parseInt(this.$route.params.id);
      this.getRequest(`/alert/alert-spontaneous/Update/?id=${id}`)
      .then(
        function (response){
          that.updates = response.data;
          console.log(that.updates);
        },
        function (error){
          console.log(error);
        }
      )
    },
    async setPertinence(pertinence) {
      const formData = new FormData();
      formData.append('id', parseInt(this.$route.params.id));
      formData.append('pertinence', pertinence);
      try {
        const response = await axios.put(`https://api.alcit.sims-technologie.com/api/v1/alert/alert-spontaneous/`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': localStorage.getItem('token'),
          },
        });
        Swal.fire(response.data.message, "L'Alerte à été traité!", "success");
        this.pertinence = pertinence;
      } catch (error) {
        console.log(error);
        Swal.fire({
          title: "Oops...",
          text: "Erreur lors du traitement de l'alerte",
          icon: "error",
          confirmButtonClass: "btn btn-primary w-xs mt-2",
          buttonsStyling: false,
          showCloseButton: true,
        });
      }
    },
    showMarker(coord) {
      this.Markers.push(coord);
    },
    showUserMarker(coord) {
      this.userPosition = coord;
    },
    openPDF(url) {
      window.open(url, '_blank');
    }
  },
  mounted() {
    this.getAlertDetails();
    this.$nextTick(() => {
      this.coversrc = document.getElementById('imageId').src;
    });
  },
};
</script>
<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <img src="@/assets/images/sound.png" id="imageId" class="d-none" alt="">
      <div class="col-12 mb-3">
        <div class="card">
          <div class="row justify-content-between">
            <div class="col-md-7">
              <div class="btn-toolbar p-3" role="toolbar">
                <button type="button" class="btn btn-primary me-2"
                  @click="$router.push(`/launchIntervention/${parseInt(this.$route.params.id)}`)">
                  <i class="mdi mdi-run"></i>
                  Déclencher intervention
                </button>
              </div>
            </div>
            <div class="col-md-5 d-flex justify-content-end">
              <b-dropdown split text="Pertinence" variant="warning" class="p-3">
                <b-dropdown-item @click="setPertinence('PERTINENT')">Pertinent</b-dropdown-item>
                <b-dropdown-item @click="setPertinence('NON_PERTINENT')">Non pertinent</b-dropdown-item>
                <b-dropdown-item @click="setPertinence('DECISIVE')">Décisif</b-dropdown-item>
              </b-dropdown>
              <strong v-if="pertinence == 'PERTINENT'" class="text-success p-3 mt-2">
                Pertinent
              </strong>
              <strong v-if="pertinence == 'NON_PERTINENT'" class="text-danger p-3 mt-2">
                Non Pertinent
              </strong>
              <strong v-if="pertinence == 'DECISIF'" class="text-primary p-3 mt-2">
                Décisif
              </strong>
              <strong v-if="pertinence == 'NORMAL'" class="text-info p-3 mt-2">
                Normal
              </strong>
            </div>
          </div>
          <div class="card-body" v-for="(alert0, index) in alert.alerts" :key="index">
            <div class="d-flex mb-4">
              <img class="d-flex me-3 rounded-circle avatar-sm" src="@/assets/images/users/citoyen.png"
                alt="Generic placeholder image" />
              <div class="flex-grow-1">
                <h5 class="font-size-13 mt-1">{{ alert0?.sender?.name }}</h5>
                <small class="text-muted">{{ alert0?.sender?.email }}</small>
              </div>
            </div>
            <div class="row mt-3 justify-content-between">
              <div class="col-md-2 text-primary">
                <strong class="font-size-13">Téléphone:</strong>
              </div>
              <div class="col-md-10">
                <h5><strong class="font-size-13">{{ alert0?.sender?.telephone }}</strong></h5>
              </div>
            </div>
            <div class="row mt-3 justify-content-between">
              <div class="col-md-2">
                <strong class="font-size-13 text-primary">Thématique:</strong>
              </div>
              <div class="col-md-10">
                <h5><strong class="font-size-13">{{ alert0?.thematic?.name }}</strong></h5>
              </div>
            </div>
            <div class="row mt-3 justify-content-between" v-if="alert0?.title?.length > 0">
              <div class="col-md-2">
                <strong class="font-size-13 text-primary">Titre:</strong>
              </div>
              <div class="col-md-10">
                <h5><strong class="font-size-13">{{ alert0?.title }}</strong></h5>
              </div>
            </div>
            <div class="row mt-3 justify-content-between" v-if="alert0?.adresse?.length > 0">
              <div class="col-md-2 text-primary">
                <strong class="font-size-13">Adresse:</strong>
              </div>
              <div class="col-md-10">
                <h5><strong class="font-size-13"> {{ alert0?.adresse }} </strong></h5>
              </div>
            </div>
            <div class="row mt-3 justify-content-between">
              <div class="col-md-2 text-primary">
                <strong class="font-size-13">Date:</strong>
              </div>
              <div class="col-md-10">
                <h5><strong class="font-size-13">{{ alert0?.created_at?.split('T')[0] }}</strong></h5>
              </div>
            </div>
            <div class="row mt-3 justify-content-between">
              <div class="col-md-2 text-primary">
                <strong class="font-size-13">heure:</strong>
              </div>
              <div class="col-md-10">
                <h5><strong class="font-size-13">{{ alert0?.created_at?.split('T')[1]?.split('.')[0] }}</strong></h5>
              </div>
            </div>
            <div v-if="alert?.audios?.length > 0">
              <b-card no-body class="mb-1">
                <b-card-header v-b-toggle.accordion-2 header-tag="header" role="tab">
                  <h6 class="m-0">
                    <a class="text-dark" href="javascript: void(0);"><strong>Audios </strong></a>
                  </h6>
                </b-card-header>
                <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
                  <b-card-body>
                    <div class="row">
                      <div class="col-xl-2 col-6" v-for="(audio, index) in alert.audios" :key=index>
                        <div class="card">
                          <AudioPlayer :option="{
                            src: `https://api.alcit.sims-technologie.com${audio.audio}`,
                            title: `Audio-${index + 1}.wav`,
                            coverImage: `${coversrc}`,
                          }" />
                        </div>
                      </div>
                    </div>
                  </b-card-body>
                </b-collapse>
              </b-card>

            </div>
            <div v-if="alert?.messages?.length > 0">
              <b-card no-body class="mb-1">
                <b-card-header v-b-toggle.accordion-2 header-tag="header" role="tab">
                  <h6 class="m-0">
                    <a class="text-dark" href="javascript: void(0);"><strong>Messages</strong></a>
                  </h6>
                </b-card-header>
                <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
                  <b-card-body>
                    <div class="row">
                      <h5>
                           {{ alert?.messages[0]?.message }}
                      </h5>
                    </div>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </div>
           
            <div v-if="alert?.photos?.length > 0">
              <b-card no-body class="mb-1">
                <b-card-header v-b-toggle.accordion-1 header-tag="header" role="tab">
                  <h6 class="m-0">
                    <a class="text-dark" href="javascript: void(0);">
                      <strong>Images </strong>
                    </a>
                  </h6>
                </b-card-header>
                <b-collapse id="accordion-1" visible accordion="my-accordion" role="tabpanel">
                  <b-card-body>
                    <div class="row" v-viewer>
                      <div class="col-xl-2 col-6" v-for="(photo, index) in alert.photos" :key=index>
                        <div class="card">
                          <img class="card-img-top img-fluid"
                            :src="`https://api.alcit.sims-technologie.com${photo.image}`" alt="Card image cap" />
                        </div>
                      </div>
                    </div>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </div>
            <div v-if="alert?.videos?.length > 0">
              <b-card no-body class="mb-1">
                <b-card-header v-b-toggle.accordion-2 header-tag="header" role="tab">
                  <h6 class="m-0">
                    <a class="text-dark" href="javascript: void(0);"><strong>Videos</strong></a>
                  </h6>
                </b-card-header>
                <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
                  <b-card-body>
                    <div class="row">
                      <div class="col-xl-2 col-6" v-for="(src, index) in alert.videos" :key=index>
                        <div class="card">
                          <video class="card-img-top img-fluid"
                            :src="`https://api.alcit.sims-technologie.com${src.video}`" controls alt="Card image cap" />
                        </div>
                      </div>
                    </div>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </div>
            <div v-if="alert?.files?.length > 0">
              <b-card no-body class="mb-1">
                <b-card-header v-b-toggle.accordion-2 header-tag="header" role="tab">
                  <h6 class="m-0">
                    <a class="text-dark" href="javascript: void(0);"><strong>Fichers </strong></a>
                  </h6>
                </b-card-header>
                <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
                  <b-card-body>
                    <div class="row">
                      <div class="col-xl-2 col-6" v-for="(file, index) in alert.files" :key=index>
                        <div class="card text-center">
                          <a @click="openPDF(`https://api.alcit.sims-technologie.com${file.file}`)" href="#">
                            <img class="card-img-top img-fluid" src="@/assets/images/pdf.png" alt="Card image cap" />
                          </a>
                          <strong class="font-size-13 mt-2">File-{{ file.file.split('/media/')[1] }}</strong>
                        </div>
                      </div>
                    </div>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </div>
            <hr />
            <div class="row mt-3 justify-content-between">
              <div class="col-md-2">
                <strong class="font-size-13 text-primary">Position zéro:</strong>
              </div>
              <div class="col-md-10">
                <ul>
                  <li>
                    <a href="#" @click="showUserMarker(alert0?.sender_current_position?.coordinates)">Voir position</a>
                  </li>
                </ul>
              </div>
            </div>
            <div v-if="alert?.coordinates?.length > 0">
              <hr />
              <div class="row mt-3 justify-content-between">
                <div class="col-md-2">
                  <strong class="font-size-14 text-primary">Positions:</strong>
                </div>
                <div class="col-md-10">
                  <ul>
                    <li v-for="(coord, index) in alert.coordinates" :key="index">
                      <a href="#" @click="showMarker(coord)">Position {{ index + 1 }}</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="form-group row mt-3">
              <div class="col-lg-12">
                <l-map :zoom="zoom" style="height: 500px;" :center="center">
                  <l-tile-layer :url="url" :maxZoom="maxZoom" />
                  <l-marker v-for="( marker, index ) in Markers" :key="index" :visible="true"
                    :lat-lng="marker.coordinate.coordinates">
                    <l-icon :icon-anchor="staticAnchor">
                      <img src="@/assets/images/leaflet/marker-icon.png" />
                    </l-icon>
                  </l-marker>
                  <l-marker :visible="true" :lat-lng="userPosition" v-if="userPosition?.length > 0">
                    <l-icon :icon-anchor="staticAnchor">
                      <img src="@/assets/images/leaflet/marker0.png" />
                    </l-icon>
                  </l-marker>
                </l-map>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- card -->
      <!-- end Col-9 -->
    </div>
  </Layout>
</template>
